<template>
  <div class="view-forgot-password">
    <div class="row mb-3">
      <div class="col-5 mx-auto">
        <img class="img-fluid" src="@/assets/images/logo.png" />
      </div>
    </div>
    <form v-if="!sended" @submit.prevent="submit">
      <h5 class="text-dark mb-4">
        Informe o e-mail cadastrado para recuperar sua senha
      </h5>

      <form-group
        v-model="form.email"
        id="email"
        label="E-mail"
        type="text"
        placeholder="Digite seu e-mail"
        :errors="errors.email"
      />

      <div class="text-center pt-5">
        <button
          type="submit"
          class="btn btn-primary btn-fixed-size"
          :disabled="!form.email || !form.email.length"
        >
          <loading :show="pageLoading">Recuperar senha</loading>
        </button>
      </div>
      <div class="d-flex justify-content-center pt-3">
        <router-link :to="{ name: 'login' }">
          Voltar para o login
        </router-link>
      </div>
    </form>
    <div v-else class="d-flex flex-column align-items-center">
      <h4 class="text-dark mb-4">
        Concluído!
      </h4>
      <p class="text-center text-secondary mb-5">
        Verifique seu e-mail e siga as instruções de recuperação da senha.
      </p>
      <router-link
        class="btn btn-primary btn-fixed-size"
        :to="{ name: 'login' }"
      >
        Voltar para o login
      </router-link>
    </div>
  </div>
</template>

<script>
import { formFields } from "@/functions/helpers.js";

export default {
  data() {
    return {
      ...formFields(["email"]),
      sended: false,
      pageLoading: false
    };
  },
  methods: {
    next() {
      this.choseSegment = true;
    },
    submit() {
      this.$message.hide();

      this.pageLoading = true;
      this.errors = {};

      this.$store
        .dispatch("user/forgot", this.form.email)
        .then(() => {
          this.sended = true;
        })
        .catch(error => {
          this.errors = error.response.data;
          if (this.errors.non_field_errors) {
            this.$message.error(this.errors.non_field_errors[0]);
          }
        })
        .finally(() => {
          this.pageLoading = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.view-forgot-password {
  width: 100%;
}
</style>
